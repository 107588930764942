.query-builder {
    .rules-group-container {
        background: rgba(230, 230, 230, 0.29);
    }

    .rules-group-container,
    .rule-container,
     .rule-placeholder {
        border: 1px solid #ced4da;
    }

    .rule-value-container label {
        justify-content: left;
    }

    .hide {
        display: none;
    }
}
