.transfer-configuration-choice.fancy-select-entry {
  .field {
    display: block;
    &:after {
      content: "\000a";
      white-space: pre;
    }
  }

  .client {
    font-weight: bold;
  }
}