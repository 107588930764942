/**
 * #refactoring
 * Side Effects with _forms.scss from the layout bundle. Will be refactored in P2-3626.
 * ~ Chris V
 */
.order-position-entries {
  > a.collection-rescue-add {
    float: right;
  }

  > .form-group.row {
    margin-bottom: 0;
    > div {
      margin: 0;
      border-top: 1px solid lightgray;
      padding-top: 13px;
      width: 100%;
      display: block;
      margin-bottom: 13px;

      .collection-actions {
        float: right;
      }

    }
  }
}