/*
 * Custom overwrites for fixed header datatable and additonal extra scrollbar styles.
 */

table.table.dataTable.no-footer.fixedHeader-floating {
    overflow-x: hidden;
    display: block;
    margin-left: -15px;
    padding-left: 15px;
    left: 0;
    right: 0;
    box-shadow: 0 2px 13px -4px rgba(0, 0, 0, 0.4);
}

.dtfh-floatingparenthead {
    top: 45px !important;
    z-index: 100;
    background: #fff;
}

.extra-scrollbar {
    height: 18px;
    overflow-x: scroll;
    background: #fff;

    &.is-fixed {
        position: fixed;
        top: 84px;
        z-index: 8;
    }
}