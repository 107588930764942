.card-body {

  .nav {
    border-bottom: 1px solid $btn-default-border;
    margin-bottom: 15px;
    padding-bottom: 15px;

    .nav-item {

      &:first-of-type {
        .nav-link {
          border-bottom-left-radius: 16px;
          border-top-left-radius: 16px;
        }
      }

      &:last-of-type {
        .nav-link {
          border-bottom-right-radius: 16px;
          border-top-right-radius: 16px;
        }
      }

      &:not(:first-of-type) {
        .nav-link {
          border-left-width: 0;
        }
      }

      .nav-link {
        background-color: #ffffff;
        border: 1px solid $btn-default-border;
        margin-bottom: -2px;
      }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
      background-color: #00aeef;
      color: #ffffff;
    }

    .nav-link.done {
      color: #636c72;
      cursor: not-allowed;
      &:after {
        background-color: $miofairSuccess;
        border-radius: 100%;
        box-shadow: 2px 2px 2px rgba(0,0,0,0.5);
        color: #ffffff;
        content: "\f00c";
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        font-size: 8px;
        padding: 2px 5px;
        position: absolute;
        margin-top: -12px;
      }
    }
  }
}


.card-body{
  .nav.tablist{
    margin:0 0 15px;
    padding:0;

    .nav-item{
      &:first-of-type{
        .nav-link{
          border-top-left-radius: 0.25rem;
        }
      }
      &:last-of-type{
        .nav-link{
          border-top-right-radius: 0.25rem;
        }
      }
      .nav-link{
        border-radius: 0;
        color:$headlineColor;
        border-bottom:1px solid $btn-default-border;
        margin-bottom: -1px;
        background:$miofairBackgroundColor;

        &.active{
          background: #fff;
          border-bottom: 1px solid #fff;
          color:$miofairColor;
        }
      }
    }
  }
}


/* Hide step done indicator in webkit browsers */
@media all and (-webkit-min-device-pixel-ratio:0)  {
  .card-body .nav .nav-link.done:after {
    display: none;
  }
}