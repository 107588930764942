.miofairreports {
    .card:hover {
        background-color: $btn-secondary-bg;
        cursor: pointer;

        .card-body {
            button {
                display: inline;
            }
        }
    }
    .active {
        background-color: $btn-secondary-bg;
    }
    .card {
        width: 330px;

        .card-body {
            button {
                position: absolute;
                top: 10px;
                right: 10px;
                display: none;
            }
        }
    }
}