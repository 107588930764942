#page-login {
    height: 100%;
    min-height: 100%;
    background: url($miofair-image-path + 'bg_login1.jpg') no-repeat center center fixed;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 40px;

    .form-signin {
        margin: 0 auto;
        padding: 15px;
        max-width: 400px;

        .checkbox {
            font-weight: normal;
        }

        .form-control {
            position: relative;
            height: auto;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 10px;
            font-size: 16px;
        }
    }
}