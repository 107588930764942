.infobox-headline {
  margin-bottom: 15px;
  display: block;
  font-size: 18px;
  font-family: "Comfortaa";
  text-align: center;

  &:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\F05a ';
    color: lighten(#212529, 25%);
  }
}

.infobox {
  min-height: 100%;
  padding: 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.infobox__section {
  margin-top: 20px;
  padding-top: 20px;
  &:not(:first-of-type){
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
  &:first-of-type{
    margin-top: 0;
    padding: 0;
  }
}

.infobox__section__headline {
  display: block;
  font-size: 14px;
  margin-bottom: 16px;

  &:before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\F05a ';
    color: lighten(#212529, 50%);
  }
}

.infobox__section__subheadline {
  display: block;
  font-size: 13px;
}

.infobox__section__entry {
  display: block;
  font-size: 12px;
}

.infobox___section__entry__adress {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  padding-left: 6px;
  margin-left: 2px;
  &:first-child {
    border-left: 0;
    padding-left: 0;
    margin-left: 0;
  }
}

.infobox__accordion__headline {
  @extend .h5;
  display: block;
  width: 100%;
  font-family: "Comfortaa";
  color: #41454b;
  text-align: left;
  cursor: pointer;

  &:after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    float: right;
    font-weight: bold;
    content: '\F106';
  }


  &:focus {
    outline: none;
  }

  &.has-one-item {
    pointer-events: none;
  }

  &.has-one-item:after {
    content: ' '!important;
  }
}

.infobox__accordion__headline:not(.collapsed){
  &:after {
    content: '\F107';
  }
}

.infox__btn-controll-all {
  display: block;
  &:after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-weight: bold;
    content: ' \F106';
  }
  &.is-active:after {
    content: ' \F107';
  }
}

.item-list-item-deleted {
  color: #F00;
  text-decoration: line-through;
}