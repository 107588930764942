.filemanager [data-notify='dismiss'] {
    margin-left: 1em;
    cursor: pointer;
}

.filemanager__refresh-button {
    cursor: pointer;
}

.filemanager--modal [data-bs-dismiss="modal"] {
    cursor: pointer;
    &.is-disabled {
        cursor: default;/*fallback*/
        cursor: not-allowed;
    }
}

.filemanager--modal {
    background: white;
    padding: 10px;
    border: 1px solid black;
    overflow-y: hidden;
    position: fixed;
    height: 70%;
    top: 15%;
    width: 80%;
    min-width: 70%;
    pointer-events: inherit;
}

.filemanager--modal {
    .filemanager__filetable {
        margin-top: 15px;
        overflow-y: auto;
        height: calc(100% - 35px - 1rem); /*subtract bootstrap-margins*/
    }
}

.filemanager__button-row  {
    display: none;
}

.filemanager--modal-select {
    .filemanager__filetable {
        height: calc(100% - 35px - 1rem - 60px); /* also subtract the added select-button height */
        tr {
            cursor: pointer;
            &.is-selected {
                background-color: #CCE5FF;
            }
        }
    }
    .filemanager__button-row  {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 60px;
        width: 100%;
    }
}

.modal-img {
    width: 100px;
    height: 100px;
}

.filemanger__button-delete {
    position: absolute;
    cursor: pointer;
    left: -5px;
    top: -5px;
    font-size: 22px;
    background: white;
    border-radius: 50%;
    border: 1px solid white;
}

.filemanager__preview-wrapper {
    position: relative;
    margin-bottom: 5px;
}

.img-thumbnail--type {
    &-pdf {
        height: 70px;
        margin-bottom: 5px;
    }
}
