#page-registration {
    height: 100%;
    min-height: 100%;
    background: url($miofair-image-path + 'bg_login1.jpg') no-repeat;
    background-size: 100%;
    padding-top: 100px;
    padding-bottom: 40px;

    .card {
        margin-top: 15px;
    }
}