.error{
  background:#00AEEF;
  color: #41454b;
}
.error__site{
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 150px;
}
.error__site__big{
  color: #fff;
  font-size: 100px;
  line-height: 150px;
  text-align: center;
  opacity: 0.8;
}
.error__site__smile{
  text-align: center;
  font-size: 50px;
  opacity: 0.8;
}
.error__info{
  padding-top: 20px;
  padding-left: 60px;
  border-left: 1px dotted rgba(255,255,255,0.5);
}
.error__info__headline {
  font-size: 30px;
  line-height: 30px;
  color: #383a40;
  font-style: italic;
}
.error__info__headline__inner{
  display: inline-block;
  border-bottom: 1px solid #383a40;
  padding-bottom: 20px;
  font-size: 30px;
  line-height: 30px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}
.error__info__content,
.error__info__content p{
  font-size: 16px;
  color: #383a40;
}
.error__checklist{
  margin-top: 50px;
}
.error__checklist__content{
  font-size: 30px;
  line-height: 25px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  padding-left: 50px;
  margin-bottom: 30px;
  color:#fff;
  &:before{
    color: rgba(255,255,255,1);
    position: absolute;
    top: 50%;
    margin-top: -16px;
    left: 0;
    font-size: 130px;
    font-weight: normal;
    content: "·";
  }
}
.error__checklist__content__link{
  font-size: 30px;
  line-height: 25px;
  font-weight: bold;
  text-transform: uppercase;
  color: #007bff;
}