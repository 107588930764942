.rc-pagination-item-active a {
    color: #fff!important;
}

.rc-pagination-disabled {
    color: #d9d9d9;
}

.rc-pagination li:focus {
    border-color: #2db7f5;
    outline: none;
    color: #2db7f5;
}