* {
    font-size: 13px;
    font-family: $defaultFont;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $defaultHeadlineFont;
    color: $headlineColor;
}

.miofair, .miovent {
    color: $miofairColor;
}

html, body {
    height: 100%;
    min-height: 100%;
}

body {
    overflow-y: scroll;
    padding-bottom: 23px;
    background-color: $miofairBackgroundColor;
}

body.dragging,
body.dragging * {
    cursor: move !important;
}

mark {
    background-color: #fbfb04;
    border-bottom: 1px dashed $miofairColor;
    padding: 0;
}

#app {
    position: relative;
    height: 100%;
}

.row-main {
    min-height: 100%;
    display: -webkit-box;
    flex-wrap: inherit;
}

.container-main {
    z-index: 50;
    padding-top: 25px;
}

.sidebar-offcanvas {
    padding-top: 21px;
    padding-left: 0;
    width: auto;
}

.content-offcanvas {
    flex: 1;
    padding-top: 15px;
    padding-bottom: 38px;
    overflow-x: auto;
}

.footer {
    background: $navbarBackgroundColor;
    bottom: 0;
    padding: 2px 0 0 0;
    color: #8b929a;
    position: fixed;
    width: 100%;
    z-index: 2000;
    height: 23px;
    box-shadow: 0 -1px 5px $miofairShadowColor;
    a:link, a:visited {
        color: $footerLinkColor;
    }
    a:hover, a:focus {
        color: $miofairColor;
    }
}

.scroll-to-top {
    width: 42px;
    height: 31px;
    text-align: center;
    margin-right: -15px;
    cursor: pointer;
    margin-top: -7px;
    padding-top: 10px;
    &__icon {
        font-size: 27px;
        color: $miofairColor
    }
}

li.breadcrumb-item:last-child {
    font-weight: bold;
}

.infoarea-toggle {
    position: relative;
    z-index: 9;
    height: 27px;
}

input[type="time"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator {
    padding: 0;
    margin: 0;
}

.ReactModal__Overlay {
    z-index: 1337;
}
