.table.dataTable {
    margin-top: 0!important;
    border-collapse: separate !important;

    tfoot {
        border-top: 2px solid black;
        font-weight: bold;
    }
    td, th {
        word-break: initial;
        position: relative;
    }

    [id^=sg-datatables-transactions_report_datatable-datetime] {
        word-spacing: 200px;
    }

}
table.dataTable thead {
    word-break: keep-all;
    white-space: nowrap;
}
.dataTables_wrapper > .row:nth-of-type(2) {
    overflow-x: auto;
}

div[class$="-toolbar"] {
    float: left;
    margin-bottom: 10px;
}

.dataTables_wrapper {
    .pagination {
        float: right;
        margin: 0;
    }

    tr.odd {
        background-color: hsla(0, 0%, 99%, 1);
    }

    .scroll-container {
        overflow: auto;
        height: calc(100vh - 265px);
        border: 1px solid #dee2e6;
    }

    // Firefox Only Solution on thead element (2019-10-10)
    .has-sticky-header > thead {
        position: sticky;
        top: 0;
        z-index: 3;
        background: #fff;
    }
}

.has-fullscreen-mode {
    position: fixed;
    z-index: 80;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    margin: 0;

    .scroll-container {
        height: calc(100vh - 140px);
    }

    .hide-on-fullscreen {
        display: none;
    }
}

.DTCR_clonedTable {
    z-index: 5;
}

/**
 * Layout fix for datatables
 */
table.dataTable th,
table.dataTable td {
    padding-left: 0;
}

.dataTables_wrapper{
    padding: 0;
}
.dataTables_length{
    float: left;
    label {
        margin: 0;
        margin-top: 3px;
    }
}
.dataTables_filter{
    float: right;
    label {
        font-weight: 400;
        white-space: nowrap;
        text-align: left;
    }
    input {
        margin-left: .5em;
        display: inline-block;
        width: auto;
    }
}
.dataTables_info{
    float: left;
}

.icon-set {
    display: inline-block;
    width: 35px;
    text-align: center;
}

.dataTables_wrapper {
    .selected {
        background-color: $selection-active-color!important;
    }

    .focused-row {
        box-shadow:
            inset 0 0 0 1px rgb(0, 174, 239),
            inset 0 1px 0 1px rgb(0, 174, 239)
        ;
    }

    .range-selection {
        background-color: desaturate($selection-active-color, 100%)!important;
    }

    .dropdown-menu {
        max-height: 300px;
        overflow-y: auto;
    }

    .dropdown-toggle.buttons-colvis {
        color: #fff;
        text-decoration: none;
        margin-left: 15px;
        padding-left: 10px;
        padding-right: 8px;
        border-radius: 2px;
        .fa-eye {
            margin-right: 5px;
        }
    }
    .buttons-columnVisibility {
        color: var(--gray);
        padding-left: 15px;
        padding-right: 30px;

        &:before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            margin-right: 5px;
            content: "\F070"; /* icon eye slashed*/
        }
        &.is-active {
            color: var(--gray-dark);
            &:before {
                content: "\F06E"; /* icon eye open */
            }
        }
        &:empty {
            display: none;
        }
    }


    .multiselect-container>li>a>label {
        padding: 3px 17px 3px 10px;
        display: block;
        word-break: keep-all;
        white-space: nowrap;
    }

    .multiselect-container>li>a>label>input[type=checkbox] {
        margin-bottom: 0;
    }

    .multiselect.dropdown-toggle {
        padding: 2px 6px;
        background: #f0f0f0;
    }

    .dataTables_length select {
        width: 75px;
        display: inline-block;
    }
}

.datatable-toolbar-multiselect {
    position: absolute;
    left: 416px;
    top: -7px;
    z-index: 9;
    button,
    .input-group-text {
        height: 30px;
    }
}

.datatable-toolbar-contract-action-btns {
    position: absolute;
    top: 14px;
    left: 415px;
    z-index: 9;
    a {
        height: 30px;
    }
}
