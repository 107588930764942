$navbarHeight: 45px;

body {
    .mainNavi {
        margin-left: auto;
    }
    .navbar--main {
        z-index: 60;
        padding-top: 0;
        padding-bottom: 0;
        height: $navbarHeight;
        box-shadow: 0 1px 5px $miofairShadowColor;

        .navbar-toggler {
            color: $navbar-inverse-link-hover-color;
            padding-top: 32px;
            padding-right: 30px;
            background-image: none;
        }
    }

    .navbar-inverse {
        background-color: $navbarBackgroundColor;
        background: linear-gradient(90deg, $navbarBackgroundColor 0%, $navbarBackgroundColor  60%, hsla(225, 7%, 45%, 1) 100%);

    }

    .navbar-brand {
        float: left;
        line-height: 20px;
        height: $navbarHeight;
        overflow: hidden;
        img {
            padding-top: 2px;
            height: $navbarHeight;
            display: block;
            margin-left: 25px;
        }
        &:hover {
            animation-name: pulse;
            animation-duration: 250ms;
            animation-iteration-count: 1;
        }
    }

    #navbarCollapse {
        > ul.mainNavi {
            .dropdown-toggle::after {
                content: none !important;
            }
            > li {
                float: left;
                margin-left: 0 !important;
                height: $navbarHeight;
                min-width: 85px;
                border-left: 1px solid $headlineColor;
                text-align: center;
                list-style-type: none;
                position: relative;
                transition: background 0.6s;
                -webkit-transition: background 0.6s;

                &.dropdown-toggle.last i{
                    display: none;
                }

                ul.menu_level_1 {
                    ul {
                        flex-direction: column !important;
                    }
                }

                .icon {
                    display: block;
                    margin: 0 auto 4px;
                    width: 20px;
                    height: 20px;
                }

                > a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    color: $footerLinkColor !important;
                    text-decoration: none;
                    transition: color 0.5s;
                    -webkit-transition: color 0.5s;
                    line-height: 20px;
                    font-size: 12px;
                    > i {
                        font-size: 20px;
                        width: 100%;
                    }
                }

                ul.menu_level_1 {
                    display: none;
                    position: absolute;
                    left: 0px;
                    min-width: 100%;
                    margin-top: -5px;
                    padding: 0px;
                    list-style-type: none;
                    background-color: $navbar-inverse-bg;
                    -webkit-box-shadow: 0 3px 5px #bbbbbb;
                    -moz-box-shadow: 0 3px 5px #bbbbbb;
                    box-shadow: 0 3px 5px #bbbbbb;

                    > li {
                        border-top: 1px solid $navbar-inverse-border;
                        text-align: left;

                        a {
                            display: block;
                            padding: 5px 10px;
                            width: 100%;
                            color: $navbar-inverse-link-color;
                        }
                    }
                    > li:hover {
                        a {
                            color: $miofairBackgroundColorInverted;
                            background-color: $navbar-inverse-border;
                            text-decoration: none;
                        }
                    }
                    > li.group {
                        border-top-width: 0px;
                    }
                    > li.group-first {
                        border-top-width: 1px;
                    }
                }
            }
            > li {
                padding-left: 20px;
                padding-right: 20px;
                width: auto;
                text-align: left;
                > a {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    line-height: 1.3;

                    span {
                        color: invert($navbarBackgroundColor) !important;
                    }

                }
            }
            > li.dropdown-toggle {
                background: url($miofair-image-path + "layout_dropdownicon.png") no-repeat bottom right;
            }

            > li.active,
            > li.current,
            > li:hover {
                background-color: darken($navbarBackgroundColor, 10) !important;
                //border-bottom: solid 6px $miofairColor;
                transition: border 0.3s;
                -webkit-transition: border 0.3s;
                > a {
                    color: invert($navbarBackgroundColor) !important;
                    text-decoration: none;
                    i {
                        color: $miofairColor !important;
                        transition: color 0.4s;
                        -webkit-transition: color 0.4s;
                    }
                }
            }
            > li:hover {
                ul.menu_level_1 {
                    display: block;
                }
            }
        }
    }
}

@media (max-width: 544px) {
    body {
        nav.navbar {
            div.collapse {
                ul.mainNavi {
                    width: 100%;
                    background-color: $navbarBackgroundColor;

                    > li {
                        float: none;
                        width: 100%;
                        height: auto;
                        min-height: 85px;

                        a {
                            padding-bottom: 24px !important;

                            i {
                                width: auto;
                            }
                            span {
                                padding-left: 5px;
                                line-height: 20px;
                                font-size: 20px;
                            }
                        }
                    }

                    > li.extended {
                        ul.menu_level_1 {
                            margin-left: -30px;
                            margin-right: -30px;
                            width: auto;
                        }
                    }

                    > li.active,
                    > li.current,
                    > li:hover {
                        border-bottom-width: 0px;
                        border-left: solid 6px $miofairColor;

                        ul.menu_level_1 {
                            display: none;
                            position: static;
                            margin-top: 0px;
                        }
                    }
                    > li.dropdown-toggle:after {
                        display: none;
                    }
                }
                ul.menu_level_1.in {
                    display: block !important;
                }
                ul.menu_level_1 {
                    > li.first {
                        border-top-width: 0px;
                    }
                }
            }
        }
    }
}

.navbar__item__image {
    width: auto;
    height: 30px;
    margin-top: 2px;
    margin-right: 10px;
    transition: all 0.2s ease-in-out;
    padding: 1px;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.navbar__item--profile:hover {
    .navbar__item__image {
        transform: rotate(360deg);
        animation-duration: 3s;
        animation-timing-function: linear;
        transform-origin: center;
        animation-name: pulse;
        animation-delay: 0.2s;
        animation-duration: .5s;
        animation-iteration-count: 1;
    }
}

@keyframes pulse {
    from {
      transform: scale(1.2);
    }

    50% {
      transform: scale(1.3);
    }

    to {
      transform: scale(1.2);
    }
}

body.mioprint {
    #navbarCollapse>ul.mainNavi>li.first {
        padding-top: 5px;
    }
    #navbarCollapse>ul.mainNavi>li>a {
        text-align: center;
    }
}
