option:disabled {
  color: $btn-default-border;
}

form {
  .business-partner-form-collection,
  .miofair-form-collection {
    > .form-group > div {
      display: flex;
    }
    div[class$='collection-actions'] {
      display: flex;

      a.btn {
        margin-left: 7px;
      }
    }
  }

  label.form-control-label {
    white-space: nowrap;
  }

  div.craue_formflow_buttons {
    display: inline;
  }

  .hide-delete a.clear-phone-number-link {
    display: none;
  }
}

input[type=color] {
  height: 50px !important;
}

.invalid-feedback {
  display: block;
}

.custom-file,
.custom-file-label,
.custom-file-input {
  cursor: pointer;
}

.custom-file-label::after {
  @extend .fas;
  content: "📁";
  line-height: 1.5;
  color: #fff;
  background-color: #007bff;
  border-color: #005cbf;
}

.custom-file:hover {
  .custom-file-label::after {
    background-color: #0062cc;
  }
}

.form__info {
  background: rgba(0, 0, 0, 0.03);
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 12px;
  padding-left: 0.8em;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  margin-top: -1px;
}

.col-form-label {
  hyphens: auto;
}


// Styles for sf form type ToogleType

.toggle {
    cursor: pointer;
    display: inline-block;
}

.toggle-switch {
    display: inline-block;
    background: #ccc;
    border-radius: 16px;
    width: 58px;
    height: 32px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
    cursor: pointer;
}
.toggle-switch:before, .toggle-switch:after {
    content: "";
}
.toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 24px;
    height: 24px;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}
.toggle-checkbox:checked + .toggle-switch {
    background: #28a745;
}
.toggle-checkbox:checked + .toggle-switch:before {
    left: 30px;
}

.toggle-checkbox {
    position: absolute;
    visibility: hidden;
}

.toggle-label {
    margin-left: 5px;
    position: relative;
    top: 2px;
}
