/**
 * Block - Card Section
 */

.card-section {
  border: 1px solid #ccc;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  margin-bottom: 30px;
}

.card-section__headline {
  margin-top: 16px;
}

.card-section__subheadline {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  line-height: 1.5;
  margin-top: -5px;
}

/**
 * Block - Card Tile
 */

.card-tile {
  transition:all 200ms linear;
  margin-bottom: 30px;
  cursor: pointer;
}

.card-tile__icon-enabled  {
  position: absolute;
  top: -6px;
  right: -5px;
  background: #fff;
  line-height: 0;
  display: none;
}

.card-tile.is-enabled {
  @extend .border-success !optional;
  .card-tile__icon-enabled {
      display: inline;
  }
}

.card-tile.is-deactivated {
  opacity: 0.3;
  pointer-events: none;
}

.card-tile__header {
  min-height: 60px;
}

.card-tile__header__title {
  padding-left: 15px;
}

.card-tile__header__col-type {
  width: 15px;
  float: left;
  margin-left: -5px;
}

.card-tile__header__col-medium {
  width: 15px;
  float: right;
  margin-right: -5px;
}
