.craue_formflow_buttons {
    overflow: hidden;
}

.craue_formflow_buttons button {
    float: right;
}

.craue_formflow_buttons button + button,
* + .craue_formflow_buttons {
    margin-right: 0.25em;
}