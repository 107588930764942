/**
 * Helper Display
 */
.h-display-none {
    display: none;
}

.h-display-block {
    display: block;
}

.h-display-inline {
    display: inline;
}

.h-height-100-perc {
    height: 100%;
}

.h-min-height-100-perc {
    min-height: 100%;
}

.h-disabled {
    pointer-events: none;
    opacity: 0.6;
    filter: grayscale(100%);
}

.h-hidden {
    height: 0!important;
    overflow: hidden;
}

.h-disable-focus:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

/**
 * Helper Position
 */
 .h-position-relative {
     position: relative;
 }

/**
 * Helper Cursor
 */
 .h-cursor-pointer {
     cursor: pointer;
 }
 .h-cursor-help {
    cursor: help;
 }

/**
 * Helper Vertical Align
 */
.h-vertical-top {
    vertical-align: top;
}

/**
 * Helper List
 */
.h-list-reset {
    margin:0;
    padding:0;
}

.h-list-item-reset {
    list-style-type: none;
}

/**
 * Helper Margins and Paddings
 */
.no-margin {margin: 0 !important}

.m-auto {
    margin: 0 auto !important
}

/**
 * Helper Border
 */
.h-border-rounded-left-0 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.h-border-rounded-right-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

$elements: (table td, table th, div, span, body,);

@each $elem in $elements{
    #{$elem}.h-border-none {
        border: none;
    }
}

.h-required::after{
    content: ' *';
}

/**
 * Helper Interaction
 */
.h-user-select-none {
    user-select: none;
}

/**
 * Helper Overflow
 */
.h-overflow-auto {
    overflow: auto;
}

.h-overflow-hidden {
    overflow: hidden;
}

/**
 * Spacing Classes
 */

@mixin generateHelperClasses() {
    $sides: (top, bottom, left, right);
    $spaces: 0;

    /**
     * Add 1' steps measurements
     */
    @for $i from 1 through 10 {
        $spaces: append($spaces, $i);
    }

    /**
     * Add 5' steps measurements
     */
    @for $i from 5 through 100 {
        @if $i % 5 == 0 {
            $spaces: append($spaces, $i);
        }
    }

    /**
     * Add 100' steps measurements
     */
    @for $i from 100 through 1000 {
        @if $i % 100 == 0 {
            $spaces: append($spaces, $i);
        }
        @if $i % 10 == 0 {
            $spaces: append($spaces, $i);
        }
    }

    /**
     * Generate helper classes for margin, padding and widths
     */
    @each $space in $spaces {
        @each $side in $sides {
            .m-#{str-slice($side, 0, 1)}-#{$space} {
                margin-#{$side}: #{$space}px !important;
            }

            .p-#{str-slice($side, 0, 1)}-#{$space} {
                padding-#{$side}: #{$space}px !important;
            }
        }
        .h-width-#{$space} {
            width: #{$space}px;
        }
        .h-max-width-#{$space} {
            max-width: #{$space}px;
        }
        .h-height-#{$space} {
            height: #{$space}px;
        }
        .h-max-height-#{$space} {
            max-height: #{$space}px;
        }
    }
}
@include generateHelperClasses();

.h-clearfix::before,
.h-clearfix::after {
    content: " ";
    display: table;
}

.h-clearfix::after {
    clear: both;
}

.h-clearfix {
    *zoom: 1;
}

.float-left,
.h-float-start {
    float: left;
}

.float-end,
.h-float-end {
    float: right;
}

.h-bold {
    &-font-bold {
        font-weight: bold;
    }
}

.h-nowrap {
    white-space: nowrap;
}

/* Input Mods */
/* clears the 'X' from Internet Explorer */
input.h-no-clear[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
input.h-no-clear[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }

/* clears the 'X' from Chrome */
input.h-no-clear[type="search"]::-webkit-search-decoration,
input.h-no-clear[type="search"]::-webkit-search-cancel-button,input.h-no-cleart[type="search"]::-webkit-search-results-button,
input.h-no-clear[type="search"]::-webkit-search-results-decoration { display: none; }

.h-bg-lightgray { background-color: rgba(0, 0, 0, 0.05) }
.h-bg-lightblue { background-color: rgb(227, 245, 251)!important; }

.h-text-lightgray { color: rgba(0, 0, 0, 0.5) }

.h-no-word-break {
    word-break: inherit!important;
    tr {
        td {
            word-break: inherit!important;
        }
    }
}

.h-odd-striped div:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.h-transe-500 {
    transition: all 500ms ease-in-out;
}

.h-transe-250 {
    transition: all 250ms ease-in-out;
}

.h-transe-100 {
    transition: all 100ms ease-in-out;
}

.h-index-999 {
    z-index: 999;
}
