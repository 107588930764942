.address-choice {
  &.fancy-select-entry {
    clear: both;

    .icon {
      float: left;
    }
    .data {
      padding-left: 68px;
      min-height: 52px;
    }
    .company1, .company2, .company3, .company4, .street, .addressAdditional, .country, .countryCode, .formattedAddress {
      display: block;
    }
    .company1 {
      font-weight: bold;
    }
  }
}
