.sidebar-offcanvas {
    background: $miofairBackgroundColorPanel;
    padding-right: 0;
    body.stage-env & {
        background-color: #FBAB7E;
        background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);

        .sidebar__navigation-item__link--sub:after {
            right: 1.15rem;
        }
    }
}

.sidebar {
    position: relative;
    height: calc(100vh - 70px);
    float: left;
    width: $sidebar__width-active;
    overflow: hidden;
    transition: all .12s ease-out;
}

.sidebar__wrapper {
    position: fixed;
    width: $sidebar__width;
    height: inherit;
    padding: 45px 15px 15px;
    border-right: 2px solid $borderColor;
    overflow-y: auto;
    overflow-x: hidden;
}

.sidebar.is-active {
    .sidebar__wrapper {
        width: inherit;
    }
}

.sidebar--level-1 {
    width: $sidebar__width;

    &.is-active {
        width: $sidebar__width-active;
    }
}

.sidebar--level-2 {
    pointer-events: none;
    width: 0;
    border-right: 0;

    .sidebar__wrapper {
        opacity: 0;
        transition: opacity .25s ease-out;
    }

    &.is-active {
        pointer-events: auto;
        width: $sidebar__width-active;
        border-right: 2px solid $borderColor;
        .sidebar__wrapper {
            opacity: 1;
        }
    }
}

.sidebar__state {
    position: absolute;
    z-index: 10;
    top: 0;
    height: 43px;
    width: 43px;
    text-align: center;
    line-height: 3.1;
    right: 0;
    border-left: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    border-bottom-left-radius: 5px;
    background: $miofairBackgroundColor;
    color: $headlineColor;
    cursor: pointer;

    &:before{
        @include icon(f054);
    }
}

.sidebar__state.is-active:before{
    @include icon(f053);
}

.sidebar__header {
    width: 206px;
    position: relative;
    padding-left: 17px;
    padding-right: 17px;
    padding-bottom: 43px;
    opacity: 0;
    white-space: nowrap;
    transition: opacity .25s ease-out;
}

.sidebar.is-active {
    .sidebar__header {
        opacity: 1;
    }
}

.sidebar__header__delete {
    line-height: normal;
    position: absolute;
    bottom: 48px;
    right: 0;
    background: $miofairBackgroundColor;
    border: 1px solid $borderColor;
    border-radius: 0.25rem;
    color: $headlineColor;
    padding: 0px 5px 1px;
    text-align: center;
    cursor: pointer;

    i {
        font-size: 12px;
        line-height: 12px;
    }

    &:hover {
        color:$miofairColor;
    }
}

.sidebar__header__headline {
    display: block;
}

.sidebar__header__subheadline {
    display: block;
    white-space: normal;
}

.sidebar__header__subheadline__link:hover {
    text-decoration: none;
}

.sidebar__header__subheadline--bottom {
    position: absolute;
    bottom: 36px;
}

.sidebar__navigation {
    overflow: hidden;
}

.sidebar__navigation__item {
    border-right: none;
    border-left: none;
    padding: 0;
    min-height: 42px;

    :first-of-type {
        border-top: none;
    }
}

.sidebar__navigation-item__link {
    display: block;
    padding: 0.75rem 1.25rem;
    position: relative;
    color: $headlineColor;
    line-height: 1.6;
    white-space: nowrap;
    font-weight: 400;

    &:before {
        border-radius: 0.2rem;
        color: $headlineColor;
        background: $miofairBackgroundColorPanel;
        font-size: 12px;
        line-height: 1;
        padding: 3px 3px 3px 3px;
        margin-right: 0.82rem;
        font-weight: 900;
    }

    &:hover {
        &:before {
            color: $miofairColor;
        }
    }

    &.is-active {
        &:before {
            background: $miofairBackgroundColorPanel;
            color: $miofairColor;
        }
    }
}

.sidebar__navigation__item.current {
    .sidebar__navigation-item__link {
        color: $miofairColor;
        &:before {
            background: $miofairBackgroundColorPanel;
            color: $miofairColor;
        }
    }
}

.sidebar__navigation-item__link__text {
    opacity: 0;
    transition: opacity .25s ease-out;
    .sidebar--level-2 &{
        white-space: normal;
    }
}

.sidebar.is-active {
    .sidebar__navigation-item__link__text {
        opacity: 1;
    }
}

.sidebar__navigation-item__link--sub {
    &:after {
        color: $footerLinkColor;
        content: "+";
        position: absolute;
        right: 0.15rem;
        font-size: 1.1em;
    }

    &.is-active:after {
        content: "-";
    }
}

.sidebar__footer {
    @media only screen and (min-height: 800px) {
        position: absolute;
        bottom: 0;
        left: 17px;
        right: 17px;
    }
}

.sidebar__footer__link__text {
    display: none;
    opacity: 0;
    transition: opacity .25s ease-out;
}

.sidebar.is-active {
    .sidebar__footer__link__text {
        display: inline;
        opacity: 1;
    }
}
