table {
    tr {
        td {
            word-break: break-all;
        }
    }
}
.table.table-miofair {
    tr {
        th {
            padding-top: 0.50rem;
            padding-bottom: 0.50rem;
        }
        td{
            padding-top: 0.50rem;
            padding-bottom: 2.2rem;
            position: relative;
        }

    }

    button[aria-expanded=true] .fa-sort-desc {
        display: none;
    }

    button[aria-expanded=false] .fa-sort-asc {
        display: none;
    }
}
.table.table-miofair,
.table.sortable {
    tr {
        div.row-actions {
            opacity: 0;
            white-space: nowrap;
            position: absolute;
            bottom: 0.50rem;
            z-index: 1;
            font-size: 12px;
        }

        .row-actions a {
            color: darken(#007bff, 15%);
        }

        button.row-toggle {
            position: absolute;
            right: 8px;
            top: 10px;
            display: none;
            padding: 0;
            width: 40px;
            height: 40px;
            border: none;
            outline: 0;
            background: 0 0;
        }
    }
    tr:hover div.row-actions {
        opacity: 1;
    }
}

table.sortable {
    .dragged {
        position: absolute;
        top: 0;
        opacity: 0.5;
        z-index: 2000;
    }
    tr {
        td:first-of-type,
        th:first-of-type {
            width: 50px;
        }
        .handler {
            cursor: move;
        }
    }
    tr.placeholder {
        display: block;
        background: red;
        position: relative;
        margin: 0;
        padding: 0;
        border: none;
    }
    tr.placeholder:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-left-color: red;
        margin-top: -5px;
        left: -5px;
        border-right: none;
    }
}

@media screen and (max-width: 782px) {
    .table.table-miofair {
        tr {
            td.primary-column {
                display: block;
                position: relative;
                padding-right: 50px;
                clear: both;

                button.row-toggle {
                    display: block;
                }
            }
            th.secondary-column,
            td.secondary-column {
                display: none;
            }
            td.secondary-column {
                position: relative;
                padding: 3px 50px 3px 35%;
                clear: both;
                border-width: 0px;
            }
            td.secondary-column:before {
                position: absolute;
                left: 10px;
                display: block;
                overflow: hidden;
                width: 32%;
                content: attr(data-colname);
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        tr.active {
            td.secondary-column {
                display: block;
            }
            div.row-actions {
                opacity: 1;
            }
        }
    }
}

.table {
    td.num, th.num {
        text-align: right;
    }

    position: relative;
    tbody {
        position: relative;
    }
}

.boards {
    .table td {
        border: 0 none;
        font-weight: bold;
        padding: 0 20px 0 0;

        .table td {
            font-weight: normal;
        }
    }
}