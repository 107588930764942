@media only screen and (max-width:1400px) {
  .contact-partner {
    td {
      display: block;

      &:nth-of-type(1) {
        padding-bottom: 0.375rem;
      }

      &:nth-of-type(2) {
        border-top: none;
        padding-left: 1.5rem;
        padding-top: 0;
      }
    }
  }
}
