// CONFIGS FOR IMPORTS
$fa-font-path: '~font-awesome/fonts';
$miofair-font-path: '../../../public/bundles/miofairlayout/fonts/';
$icon-font-path: '../../../public/bundles/mopabootstrap/fonts/bootstrap/';
$miofair-image-path: '../../../public/bundles/miofairlayout/images/';

// Bootstrap Custom
$primary: #00AEEF;
$btn-primary-text: #fff;

// IMPORTS THIRD PARTY FILES
@import '../../node_modules/bootstrap/scss/bootstrap';
.btn-primary,
.btn-primary:hover {
    color: #fff!important;
}

@import '../../node_modules/jquery-ui-dist/jquery-ui.css';
@import '../../node_modules/select2/src/scss/core.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/css/all.css';
@import '../bundles/LayoutBundle/vendor/redactor/_scss/redactor.scss';
@import '../../bower_deprecated/datatables-plugins/integration/font-awesome/dataTables.fontAwesome.css';
@import '../../node_modules/jstree/dist/themes/default/style.css';
@import '../../node_modules/bootstrap-multiselect-neoli/dist/css/bootstrap-multiselect.css';
@import '../../node_modules/rc-pagination/assets/index.css';
@import '../../node_modules/jQuery-QueryBuilder/dist/css/query-builder.default.css';
@import '../../node_modules/datatables.net-fixedheader-dt/css/fixedHeader.dataTables.css';
@import '../../node_modules/datatables.net-buttons-bs5/css/buttons.bootstrap5.css';

// - AdminBundle
@import '../bundles/AdminBundle/scss/configCreator.scss';

// - BusinessDataSyncBundle
@import '../bundles/BusinessDataSyncBundle/scss/table.scss';
// - BusinessPartnerBundle
@import '../bundles/BusinessPartnerBundle/scss/select2-address-renderer.scss';
@import '../bundles/BusinessPartnerBundle/scss/select2-business-partner-renderer.scss';
@import '../bundles/BusinessPartnerBundle/scss/select2-contact-person-renderer.scss';

// - ClientBundle
@import '../bundles/ClientBundle/scss/_client_plugins.scss';

// - ContractBundle
@import '../bundles/ContractBundle/scss/order-variant-selection.scss';
@import '../bundles/ContractBundle/scss/order-position.scss';
@import '../bundles/ContractBundle/scss/datatable-fixed-header.scss';

// - EventBundle
@import '../bundles/EventBundle/scss/select2-quota-condition-renderer.scss';
@import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
@import '../bundles/EventBundle/scss/editorCustomStyles.scss';

// - FileManagerBundle
@import '../bundles/FileManagerBundle/scss/filemanager.scss';

// - LayoutBundle
@import '../bundles/LayoutBundle/scss/miofair.scss';
@import '../bundles/LayoutBundle/scss/flags.scss';
@import '../bundles/LayoutBundle/scss/craue_buttons.scss';
@import '../bundles/LayoutBundle/scss/rc-pagination_mod.scss';
@import '../bundles/LayoutBundle/scss/querybuilder.scss';
@import '../bundles/LayoutBundle/css/EmailPills/customStyles.css';

// - LayoutBundle
@import '../bundles/ProductBundle/scss/product.scss';

// - TemplateBundle
@import '../bundles/TemplateBundle/scss/template-master.scss';

// - UserBundle
@import '../bundles/UserBundle/scss/usergroup.scss';
