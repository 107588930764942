.select2-container--default .select2-selection--multiple {
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.select2-container--default.select2-container--focus .select2-selection, .select2-container--default.select2-container--open .select2-selection {
    border: 1px solid #66afe9;
}

.select2-container--default .select2-dropdown {
    border: 1px solid #66afe9;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-color: transparent;
}

.form-group .select2-container {
    width: 100% !important;
    table-layout: fixed;
}

.select2-container {
    .select2-selection--single {
        height: 38px;

        .select2-selection__rendered {
            line-height: 38px;
        }
        .select2-selection__arrow {
            height: 36px;
        }
    }
}

.is-locked {
    @extend .h-disabled;
    .select2-selection__choice__remove {
        display: none !important;
    }
}

.contains-is-locked .select2-results__option[aria-selected=true]{
    display: none!important;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    border-radius: 50%;
    border: 2px solid $miofairDanger;
    background: #fff;
    color: $miofairDanger;
    font-size: 18px;
    width: 1em;
    height: 1em;
    text-align: center;
    line-height: 0.77;
    margin-top: 9px;
    padding: 0;
}
