$miofairColor: #00AEEF;
$miofairSuccess: #6EC53F;
$miofairInfo: #83a9de;
$miofairWarning: #f0ad4e;
$miofairDanger: #D74646;

$miofairShadowColor: #666666;

$miofairBackgroundColor: #F6F8F9;
$miofairBackgroundColorPanel: #FFFFFF;
$miofairBackgroundColorInverted: #383a40;

$headlineColor: #41454b;
$footerLinkColor: #8B929A;

$miofairHeadlineBackgroundColor: #FFF;

$borderRadius: 5px;
$borderColor: rgba(0, 0, 0, 0.125);

$defaultFont: 'Open Sans', sans-serif;
$defaultHeadlineFont: "Comfortaa";

// Bootstrap default overrides

$btn-font-weight: normal;

$btn-default-color: $miofairColor;
$btn-default-bg: #FCFCFC;
$btn-default-border: #ccc;

$btn-primary-color: $miofairBackgroundColor;
$btn-primary-bg: $miofairColor;
$btn-primary-border: darken($btn-primary-bg, 15%);

$btn-success-color: #fff;
$btn-success-bg: $miofairSuccess;
$btn-success-border: darken($btn-success-bg, 15%);

$btn-info-color: #fff;
$btn-info-bg: $miofairInfo;
$btn-info-border: darken($btn-info-bg, 15%);

$btn-warning-color: #fff;
$btn-warning-bg: $miofairWarning;
$btn-warning-border: darken($btn-warning-bg, 15%);

$btn-danger-color: #fff;
$btn-danger-bg: $miofairDanger;
$btn-danger-border: darken($btn-danger-bg, 15%);

$btn-secondary-bg: #F6F8F9;

$brand-primary: $miofairColor;
$link-color: $miofairColor;
$link-active-color: darken($miofairColor, 15%);
$selection-active-color: lighten($miofairColor, 50%);
$component-active-bg: $miofairColor;

$brand-success: $miofairSuccess;
$brand-info: $miofairInfo;
$brand-warning: $miofairWarning;
$brand-danger: $miofairDanger;

$input-border-focus: $brand-primary;

$state-success-text: darken($brand-success, 20);
$state-success-bg: lighten($brand-success, 20);
$state-success-border: darken(adjust-hue($state-success-bg, -10), 5%);

$state-info-text: darken($brand-info, 20);
$state-info-bg: lighten($brand-info, 20);
$state-info-border: darken(adjust-hue($state-info-bg, -10), 7%);

$state-warning-text: darken($brand-warning, 20);
$state-warning-bg: lighten($brand-warning, 20);
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%);

$state-danger-text: darken($brand-danger, 20);
$state-danger-bg: lighten($brand-danger, 20);
$state-danger-border: darken(adjust-hue($state-danger-bg, -10), 5%);

$pagination-color: $link-color;
$pagination-active-bg: $brand-primary;
$pagination-active-border: $brand-primary;

$label-default-bg: #636C73;
$label-primary-bg: $brand-primary;
$label-success-bg: $brand-success;
$label-info-bg: $brand-info;
$label-warning-bg: $brand-warning;
$label-danger-bg: $brand-danger;
$progress-bar-bg: $brand-primary;
$progress-bar-success-bg: $brand-success;
$progress-bar-warning-bg: $brand-warning;
$progress-bar-danger-bg: $brand-danger;
$progress-bar-info-bg: $brand-info;
$panel-primary-border: $brand-primary;
$panel-primary-heading-bg: $brand-primary;
$panel-success-text: $state-success-text;
$panel-success-border: $state-success-border;
$panel-success-heading-bg: $state-success-bg;
$panel-info-text: $state-info-text;
$panel-info-border: $state-info-border;
$panel-info-heading-bg: $state-info-bg;
$panel-warning-text: $state-warning-text;
$panel-warning-border: $state-warning-border;
$panel-warning-heading-bg: $state-warning-bg;
$panel-danger-text: $state-danger-text;
$panel-danger-border: $state-danger-border;
$panel-danger-heading-bg: $state-danger-bg;
$nav-pills-active-link-hover-bg: $component-active-bg;

//=== Inverted navbar
// Reset inverted navbar basics
$navbarBackgroundColor: #383a40 !default;
$navbarForegroundColor: #F6F8F9 !default;
$navbar-inverse-color: $navbarForegroundColor;
$navbar-inverse-bg: #ffffff;
$navbar-inverse-border: #eeeeee;

// Inverted navbar links
$navbar-inverse-link-color: lighten(#636C73, 15%);
$navbar-inverse-link-hover-color: #fff;
$navbar-inverse-link-hover-bg: transparent;
$navbar-inverse-link-active-color: $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg: transparent;

// Sidebar
$sidebar__width: 83px;
$sidebar__width-active: 265px;
