.contact-person-choice {
  &.fancy-select-entry {
    .salutation, .firstName, .lastName, .title {
      font-weight: bold;

      &:after {
        content: "\00a0";
      }
    }

    .lastName:after, .emailAddressBusiness:after, .department:after {
      content: "\000a";
      white-space: pre;
    }
  }
}
