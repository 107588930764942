.quota-table {
    &__header {
        padding-left: 35px;
        font-weight: bold;
    }
    &__product {
        padding: 5px;
        border-top: solid;
        border-width: 1px;
    }
    &__header_name {
        width: 400px;
        font-weight: bold;
        text-transform: capitalize;
    }
    &__variant {
        padding: 3px 3px 3px 35px;
    }
    &__variant_name {
        width: 400px;
    }
    &__sticky-container {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        height: 200px;
    }
    &__sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 5px;
        max-width: 860px;
    }
    &__link[aria-expanded=true] .fa-chevron-right {
        display: none;
    }
    &__link[aria-expanded=false] .fa-chevron-down {
        display: none;
    }
}
