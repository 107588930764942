@mixin gradient($from, $to) {
  background-color: $from;
  background-image: -moz-linear-gradient($from, $to);
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from($from), to($to));
  background-image: -webkit-linear-gradient($from, $to);
  background-image: -o-linear-gradient($from, $to);
}

@mixin greyGradient() {
  @include gradient(#FFFFFF, #E6E6E6);
}

@mixin cyanGradient() {
  @include gradient($miofairColor, #0088CC);
  color: #FFFFFF;
}

@mixin icon($unicode) {
  $withslash: "\"\\#{$unicode}\"";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: unquote($withslash);
}
