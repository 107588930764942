/**
 * SCSS to overwrite bootstrap properties
 */
div.card.card-tabs {
    border-width: 0px;
    border-bottom-width: 1px;

    .card-header {
        background-color: #F6F8F9;
    }
    .card-body {
        border: 0px solid rgba(0, 0, 0, 0.125);
        border-left-width: 1px;
        border-right-width: 1px;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }
}

div.card.card-beside-tabs {
    margin-top: 43.75px;
}

div.card.card-tab-filter {
    margin-top: 45px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

div#breadcrumbs {
    .card-body {
        padding-bottom: 0;
        ol {
            padding: 0;
            background-color: inherit;
        }
    }
}

form div.radio-inline {
    div.radio {
        display: inline-block;
        padding-right: 15px;
    }
}

a {
  text-decoration: none;
  color: $link-color;
  &:hover {
    color: $link-active-color;
  }
}

.focus.no-focus {
    box-shadow: none;
}

.form-group {
    margin-bottom: 1rem;
}

select.form-control {
    appearance: auto!important;
}

.h-float-right,
.float-right {
    float: right;
}

.h-float-left,
.float-left {
    float: left;
}

.btn-block {
    display: block;
    width: 100%;
}

.input-group-append {
    display: flex;
}

.nav-link {
    color: #00aeef;
}

.card-columns .card {
    display: inline-block;
    width: 100%;
}

.badge-success {
    color: #fff !important;
    background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

.progress {padding: 0;}

// LEGACY BOOTSTRAP 4 FONT WEIGHTS CLASSES
.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-lighter {
    font-weight: lighter !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: bolder !important;
}
