.card-body{
  .nav.form-flow-steps{
    counter-reset:step;

    padding:0 0 30px;
    display: flex;

    .nav-item:first-of-type,
    .nav-item:last-of-type{
      .nav-link {
        border-radius: 0;
      }
    }
    .nav-item{
      flex:1;
      text-align: center;
      height: 50px;
      position: relative;
      padding-top: 70px;

      &:before{
        content: " ";
        height: 3px;
        width: 100%;
        display: block;
        background: #e7e7e7;
        position: absolute;
        top:30px;
        left:0;
      }
      &:after{
        counter-increment: step;
        content: counter(step);
        border-radius: 100%;
        height: 40px;
        width: 40px;
        color: #868e96;
        display: inline-block;
        background: #e7e7e7;
        position: absolute;
        top: 10px;
        left: 50%;
        margin-left: -20px;
        font-size: 24px;
        line-height: 38px;
        z-index: 10;
      }
      &.done,
      &.active{
        &:before{
          background: $miofairColor;
        }
        &:after{
          background: $miofairColor;
          color: #fff;
        }
      }

      &:first-of-type{
        .nav-link {
          border-radius: 0;
        }
      }
      &:last-of-type{
        .nav-link {
          border-radius: 0;
        }
      }

      .nav-link {
        margin:0;
        padding: 0;
        border:none;
        display: inline-block;

        &.done{
          &:after{
            content:none;
          }
        }

        &.active{
          color:$headlineColor;
          background: none;
        }
      }
    }
  }
}
