.user-listing {
    margin-bottom: 2rem;
}
.user-listing__item {
    @extend .clearfix !optional;
    word-break: break-all;

    &__icon {
        display: block;
        width: 20px;
        float: left;
    }
    &__text {
        display: block;
        margin-left: 20px;
    }
    &__username {
        cursor: help;
    }
}

.fos_user_group_edit {
    .form-check {
        padding-left: 0;
    }
    .nav .nav-item:not(:first-of-type) .nav-link {
        border-left-width: 1px;
    }
}
