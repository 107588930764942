form[name="ticket_template_master"] {
  ul.nav {
    li.nav-item.disabled-item {
      a {
        color: grey;
        opacity: 0.8;
        cursor: default;
      }
    }
  }
}