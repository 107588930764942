.business-partner-choice {
  span:first-child {
    font-weight: bold;
  }

  span {
    display: block;
  }
}

.client-access-label {
  white-space: pre-wrap;
}