.input-group.input-group-unstyled input.form-control {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.input-group-unstyled .input-group-addon {
    border-radius: 4px;
    border: 0;
    background-color: transparent;
}
.input-group-addon a {
  font-size: 1.5em;
}
.input-group-addon .badge {
    position: absolute;
    top: 0;
    right: 0;
}