form {
  .counted_text-container {
    font-size: 90%;
    margin-bottom: 3em;
  }
  .counted_text-counter {
    position: absolute;
    bottom: -1.7em;
    left: 2.5em;
  }
  .counted_text-exceeded {
    color: red;
  }
}