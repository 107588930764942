.card {
    margin-bottom: 15px;
}

.card-columns {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;

    .card {
        .card-header {
            text-overflow: ellipsis;
            white-space: nowrap;
            .card-title {
                display: inline-block;
                max-width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin: 0;
                padding-right: 30px;
            }
        }
        .card-body {
            border: 0 !important;

            div[data-bs-toggle='popover'] {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

.mcdd-dropzone {
    text-align: center;
    border: 0.2em dashed #ddd;
    padding: 1em;
    margin: 0.5em 0;

    p {
        margin: 0;
        color: #ddd;
        font-size: 2rem;
        font-weight: bold;
    }
}

@media (min-width: 890px) {
    .card-columns:not(.single-column) {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

@media (min-width: 1280px) {
    .card-columns:not(.single-column):not(.double-column) {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
    }
}

.card.special-role {
    border-color: red;
}


.card__close {
    cursor: pointer;
    position: absolute;
    top: -15px;
    right: -12px;
    font-size: 14px;
    color: #fff;
    border: 1px solid #545b62;
    border-radius: 50%;
    padding: 5px 8px;
    background: #545b62;
}
