@font-face {
  font-family: 'PT Sans';
  src: url($miofair-font-path + 'PTS55F_W.eot');
  src: local('PT Sans'), url($miofair-font-path + 'PTS55F_W.woff') format('woff'),
  url($miofair-font-path + 'PTS55F_W.ttf') format('truetype'),
  url($miofair-font-path + 'PTS55F_W.svg#PTSans-Regular') format('svg');
}

@font-face {
  font-family: 'PT Sans';
  src: url($miofair-font-path + 'PTS75F_W.eot');
  font-style: normal;
  font-weight: bold;
  src: local('PT Sans Bold'), url($miofair-font-path + 'PTS75F_W.woff') format('woff'),
  url($miofair-font-path + 'PTS75F_W.ttf') format('truetype'),
  url($miofair-font-path + 'PTS75F_W.svg#PTSans-Bold') format('svg');
}

@font-face {
  font-family: 'Comfortaa';
  src: url($miofair-font-path + 'comfortaa_bold-webfont.eot');
  src: url($miofair-font-path + 'comfortaa_bold-webfont.eot?#iefix') format('embedded-opentype'),
  url($miofair-font-path + 'comfortaa_bold-webfont.woff') format('woff'),
  url($miofair-font-path + 'comfortaa_bold-webfont.ttf') format('truetype'),
  url($miofair-font-path + 'comfortaa_bold-webfont.svg#comfortaabold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  src: url($miofair-font-path + 'comfortaa_regular-webfont.eot');
  src: url($miofair-font-path + 'comfortaa_regular-webfont.eot?#iefix') format('embedded-opentype'),
  url($miofair-font-path + 'comfortaa_regular-webfont.woff') format('woff'),
  url($miofair-font-path + 'comfortaa_regular-webfont.ttf') format('truetype'),
  url($miofair-font-path + 'comfortaa_regular-webfont.svg#comfortaaregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
