.form-actions {
    @include clearfix;
    margin-top: 6px;
    padding-top: 6px;
    border-top: 1px solid #ccc;
    text-align: right;
}

.form-inline-editing {
    .form-inline-editing-text {
        padding-top: calc(0.375rem + 1px);
        cursor: pointer;
        i.fas {
            display: none;
        }
    }
    .form-inline-editing-text:hover {
        i.fas {
            display: inline;
        }
    }
}
.form-inline-editing-group {
    .form-inline-editing-text {
        cursor: default;
        i.fas {
            display: none !important;
        }
    }
}