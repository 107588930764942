.re-toolbar {
    float: none!important;
    margin-bottom: 0!important;
    .rdw-link-modal-btn {
        font-size: 11px;
    }
}

.re-editor {
    min-height: 12rem;
    resize: vertical;
    border-radius: 2px;
    border: 1px solid #00AEEF;
    .public-DraftStyleDefault-block {
        margin: 1em 1em;
    }
}

.re-editor-lang-tabs {
    &__nav-item {
        margin-bottom: -1px;
        background: 0 0;
        border: 1px solid transparent;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        display: block;
        padding: .5rem 1rem;
        text-decoration: none;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
        &.active {
            color: #495057;
            background-color: #fff;
            border-color: #dee2e6 #dee2e6 #fff;
        }
    }
}
